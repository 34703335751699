import { AlertTitle } from '@mui/material';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { StyledAlert } from './style';
import {
  Report as ReportIcon,
  CheckCircle as CheckCircleIcon,
  Warning as WarningIcon,
  Info as InfoIcon,
} from '@mui/icons-material';

/* eslint-disable-next-line */
export interface InlineStatusMessageProps {
  alertType: 'warning' | 'success' | 'error' | 'info';
  text: string;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  alertTitle?: string;
  hideClose?: boolean;
}

export function InlineStatusMessage({
  alertType,
  text,
  visible,
  setVisible,
  hideClose,
  alertTitle,
}: InlineStatusMessageProps) {
  const icon = useMemo(
    () =>
      ({
        error: <ReportIcon />,
        success: <CheckCircleIcon />,
        warning: <WarningIcon />,
        info: <InfoIcon />,
      }[alertType]),
    [alertType]
  );
  const hidden = !visible;
  return (
    <StyledAlert
      variant="outlined"
      data-testid="InlineStatusMessage"
      hidden={hidden}
      icon={icon}
      severity={alertType}
      onClose={hideClose ? undefined : () => setVisible(false)}
    >
      {alertTitle && (
        <AlertTitle className="bodyHeavyFont snap-color-grey-500">
          {alertTitle}
        </AlertTitle>
      )}
      <div className="bodyFont snap-color-grey-500">{text}</div>
    </StyledAlert>
  );
}

export default InlineStatusMessage;
