import { useTranslations } from 'next-intl';
import cn from 'classnames';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import s from './shared-ui-merchant-success-box.module.css';

export function SharedUiMerchantSuccessBox() {
  const t = useTranslations();

  return (
    <div className="snap-row pt-2" data-testid="merchantSuccessBox">
      <div className={cn(s['success-message'])}>
        <h3
          className={cn(
            s['success-message-title'],
            'text-center',
            'headingMHeavy'
          )}
        >
          <span className="items-center flex flex-col">
            <CheckCircleOutlineIcon fontSize="medium" />
          </span>
          {t('shared.successComponent.successTitle')}
        </h3>
        <p className="text-center headingM py-3">
          {t('shared.successComponent.successDescription')}
        </p>
        <h3 className={cn(s.h3, 'text-center headingMHeavy ')}>
          {t('shared.successComponent.notifyText')}
        </h3>
      </div>
    </div>
  );
}

export default SharedUiMerchantSuccessBox;
