import { styled } from '@mui/material';
import { Alert } from '@mui/material';
import { colors } from '@snap/colors';

export const StyledAlert = styled(Alert)(({ theme }) => ({
  '&.MuiAlert-outlinedInfo': {
    '.MuiAlert-icon': {
      color: colors.SnapBlue,
    },
  },
}));
